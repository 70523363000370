import { registerApplication, start } from "single-spa";
import config from "./config";

async function getUser() {
  const response = await fetch(`${config.appURL}/user`, {
    credentials: "include",
  });
  const json = await response.json();
  if (!json.token) {
    window.location.replace(`${config.appURL}/login`);
  } else {
    const data = await fetch(
      `${config.appURL}/api/tenant-service/users/ShowWithConfig?id=${json.users[0].id}`,
      {
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );

    const res = await data.json();

    registerApplication({
      name: "@unity/header",
      app: () => System.import("@unity/header"),
      activeWhen: ["/"],
      customProps: {
        domElement: document.getElementById("header"),
        auth: res.data,
        expressURL: config.appURL
      },
    });

    if(res.data.access !== null)
    {
      registerApplication({
        name: "@unity/sidebar",
        app: () => System.import("@unity/sidebar"),
        activeWhen: ["/"],
        customProps: {
          domElement: document.getElementById("sidebar"),
          auth: res.data,
          theme: res.data.theme,
          expressURL: config.appURL
        },
      });

      Object.keys(res.data.access).map((key, val) => {
        registerApplication({
          name: `@unity/${key}`,
          app: () => System.import(`@unity/${key}`),
          activeWhen: [res.data.access[key].options.active_when],
          customProps: {
            domElement: document.getElementById("content"),
            auth: res.data,
            theme: res.data.theme,
            custom: res.data.access[key].options.props,
          },
        });
      })
    }

    start({
      urlRerouteOnly: true,
    });
  }
}

getUser();