const appURLtype =
    process.env.NODE_ENV === "production"
        ? "https://treetops.time-and-attendance.mybe.software"// TODO!
        : "http://localhost:8080";

module.exports = {
    // ports
    serverPort: 8080,
    //This app url
    appURL: appURLtype,
};
